<template>
  <div class="list" v-if="dialogVisible">
    <div class="list-main">
      <section>
        <img class="title" src="@/assets/map/list-title.png" />
        <i class="el-icon-circle-close icon" @click="dialogVisible = false"></i>
      </section>
      <section class="list-main-search">
        <span style="color: #07eaff">项目名称：</span>
        <el-input v-model="projName" placeholder="请输入内容" class="input"></el-input>
        <div class="btn" @click="getList()">查询</div>
      </section>
      <section class="list-main-table">
        <el-table :data="tableData" border>
          <el-table-column show-overflow-tooltip align="center" prop="projName" label="项目名称" width="180"> </el-table-column>
          <el-table-column show-overflow-tooltip align="center" prop="contractNo" label="合同编号" width="180"> </el-table-column>
          <el-table-column show-overflow-tooltip align="center" prop="developer" label="开发商"> </el-table-column>
          <el-table-column show-overflow-tooltip align="center" prop="constractor" label="承建商"> </el-table-column>
          <el-table-column show-overflow-tooltip align="center" label="销售模式">
            <template slot-scope="scope">
              {{ scope.row.sellMode == 'SELL' ? '销售' : '出租' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="项目类别">
            <template slot-scope="scope">
              {{ scope.row.projType | getType }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="center" header-align="center" width="90" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="to-detail" @click="showMore(scope.row)">查看详情</div>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="list-main-back">
        <el-pagination background layout="prev, pager, next" @current-change="changePage" :page-size="10" :total="total" />
      </section>
    </div>
  </div>
</template>
<script>
import {cloudList} from '../api.js';
export default {
  data() {
    return {
      total: 0,
      pageNum: 1,
      dialogVisible: false,
      projName: '',
      tableData: []
    };
  },
  filters: {
    getType(val) {
      switch (val) {
        case 'ZZ':
          return '住宅';
        case 'SY':
          return '商用';
        case 'XZL':
          return '写字楼';
        case 'ORTHER':
          return '其他';
      }
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.resetData();
        this.getList();
      }
    }
  },
  methods: {
    resetData() {
      this.total = 0;
      this.pageNum = 1;
      this.tableData = [];
    },
    changePage(page) {
      this.pageNum = page;
      this.getList();
    },
    async getList() {
      let res = await cloudList({
        projName: this.projName,
        pageSize: 8,
        pageNum: this.pageNum
      });
      if (res.errno === 0) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
    async showMore(item) {
      this.$emit('toDetail', {id: item.id});
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.list {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background: rgba(1, 3, 9, 0.7);
  font-size: 15px;
  &-main {
    padding: 0 75px;
    position: absolute;
    top: calc(50% - 369px);
    left: calc(50% - 660px);
    background: url('../../../assets/map/list.png') no-repeat center/100%;
    z-index: 98;
    width: 1320px;
    height: 738px;
    .title {
      width: 269px;
      height: 55px;
      display: block;
      margin: -18px auto 0;
    }
    .icon {
      font-size: 36px;
      color: #34defc;
      position: absolute;
      top: -18px;
      right: 0;
    }
    &-search {
      display: flex;
      height: 102px;
      align-items: center;
      padding: 35px 0 30px 0;
      border-bottom: 2px solid #0796c5;
      .input {
        width: 200px;
        height: 100%;
        margin-right: 10px;
        & ::v-deep .el-input__inner {
          color: #0697c7;
          height: 100%;
          border-radius: 50px;
          background-color: transparent;
          border-color: #0697c7;
        }
      }
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 97px;
        height: 100%;
        border-radius: 50px;
        border: 1px solid #0697c7;
      }
    }
    &-table {
      margin-top: 30px;
      & ::v-deep .el-table {
        background-color: transparent;
      }
      & ::v-deep tr {
        background-color: transparent;
      }
      & ::v-deep th {
        color: #fff;
        background-color: rgba(0, 156, 255, 0.3);
      }
      & ::v-deep td {
        color: #06d5f5;
        background-color: rgba(0, 156, 255, 0.1);
      }
      & ::v-deep .el-table--border .el-table__cell {
        border-color: RGB(4, 36, 103);
        border-right: 1px solid #0454a6;
        border-bottom: 1px solid #093477;
      }
      & ::v-deep .el-table--border {
        border-color: transparent;
      }
      .el-table--border::after,
      .el-table--group::after {
        background-color: RGB(4, 36, 103);
      }
      .el-table--border::before,
      .el-table--group::before {
        background-color: RGB(4, 36, 103);
      }
      & ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: RGB(4, 36, 103);
      }
      .to-detail {
        cursor: pointer;
        width: 100%;
        height: 23px;
        border-radius: 50px;
        border: 1px solid #06d5f5;
        color: #06d5f5;
      }
    }
    &-back {
      padding: 0 72px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 90px;
      left: 0;
      & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #00ebff;
      }
      & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #00ebff;
        color: #fff;
      }
      & ::v-deep .el-pagination.is-background .btn-next,
      .el-pagination.is-background .btn-prev,
      .el-pagination.is-background .el-pager li {
        background-color: #999eb9;
      }
    }
  }
}
</style>
